var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "试卷基本信息",
        },
        on: {
          "head-save": _vm.headSave,
          "head-save-back": function ($event) {
            return _vm.headSave(true)
          },
          "head-cancel": _vm.headCancel,
          "generate-paper": _vm.generatePaper,
        },
      }),
      _c("form-layout", {
        ref: "formLayout",
        attrs: { column: _vm.formColumn, dataForm: _vm.dataForm },
        on: {
          "update:dataForm": function ($event) {
            _vm.dataForm = $event
          },
          "update:data-form": function ($event) {
            _vm.dataForm = $event
          },
        },
      }),
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.tableHeadOption,
          "head-title": "试题",
        },
        on: {
          "head-remove": _vm.headRemove,
          "head-generate-topic": _vm.openGenerateTopicDialog,
          "head-select-topic": _vm.openSelectTopicsDialog,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayTableTopic",
        attrs: {
          "grid-row-btn": _vm.gridRowBtn,
          "table-options": _vm.tableTopicOptions,
          "table-data": _vm.pageData,
          "table-loading": _vm.tableLoading,
        },
        on: {
          "page-current-change": _vm.tableOnLoad,
          "page-size-change": _vm.tableOnLoad,
          "page-refresh-change": _vm.tableOnLoad,
          "row-remove": _vm.rowRemove,
        },
      }),
      _c(
        "div",
        { staticClass: "bottomPagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.page.currentPage,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.page.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.page.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          staticClass: "paperDialog qmDialog",
          attrs: {
            title: "选择试题",
            modal: true,
            "modal-append-to-body": false,
            visible: _vm.selectTopicsDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectTopicsDialog = $event
            },
            close: _vm.closeDialog,
          },
        },
        [
          _c(
            "el-container",
            [
              _c("CommonTree", {
                attrs: {
                  titleShow: false,
                  treeData: _vm.examinationTreeData,
                  defaultProps: _vm.examinationDefaultProps,
                  searchTitle: _vm.searchTitle,
                  showCheckbox: false,
                },
                on: { getNodeClick: _vm.treeNodeClick },
              }),
              _c(
                "el-main",
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: { "search-columns": _vm.searchColumns },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "table-options": _vm.topicOptions,
                      "table-data": _vm.topicData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.paperPage.total,
                      page: _vm.paperPage,
                    },
                    on: {
                      "page-current-change": _vm.topicOnLoad,
                      "page-size-change": _vm.topicOnLoad,
                      "page-refresh-change": _vm.topicOnLoad,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "paperDialog qmDialog",
          attrs: {
            title: "生成试题",
            modal: true,
            "modal-append-to-body": false,
            visible: _vm.generateTopicsDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.generateTopicsDialog = $event
            },
            close: _vm.generateTopicCloseDialog,
          },
        },
        [
          _vm.generateTopicsDialog
            ? _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    [
                      _c("head-layout", {
                        attrs: {
                          "head-btn-options": _vm.generateTopicsHeadOption,
                          "head-title": "试题类型",
                        },
                        on: {
                          "head-remove": _vm.headRemove,
                          "head-add-tabs": _vm.headAdd,
                        },
                      }),
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        attrs: {
                          "grid-row-btn": _vm.gridRowBtn,
                          "table-options": _vm.tableOptions,
                          "table-data": _vm.tableData,
                          "table-loading": _vm.tableLoading,
                        },
                        on: { "row-remove": _vm.rowRemove },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.generateTopicCloseDialog } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.generateTopicConfirm },
                },
                [_vm._v("生成试题")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }